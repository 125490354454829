.page-tasks {
  .task {
    width: 100%;
    max-width: 650px;
    margin: 25px auto;
    box-shadow: 0 1px 2px 0 #2c2f3c0f, 0 3px 10px 0 #2c2f3c17;
    background-color: #fff;
    border-radius: 5px;

    .image {
      display: none;

      @media (min-width: 767px) {
        display: inline-block;
        width: 230px;
        height: 240px;
        vertical-align: top;
        background: url("https://static.cdn.epam.com/uploads/13a4cc94b4e4f8266fa45cc794bb4748/EPM-ADPT/Legal_and_compliance_part_1.png") center center / 100% no-repeat, linear-gradient(242.61deg, #869feb33 0%, #436ad633 100%);
        background-size: contain;
        border-radius: 6px 20px 20px 6px/6px 130px 128px 6px;
      }
    }

    .content {
      padding-left: 25px;
      padding-right: 15px;
      display: inline-block;
      width: 100%;
      vertical-align: top;
      box-sizing: border-box;

      @media (min-width: 767px) {
        width: calc(100% - 275px);
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        color: #525462;
        margin: 20px 0;
      }

      .description {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
        color: #525462;
      }

      .info {
        margin-bottom: 20px;

        .accept {
          color: #fff;
          background: #009ecc;
          font-size: 14px;
          text-decoration: none;
          padding: 8px 40px;
          border-radius: 5px;
          display: inline-block;
        }

        .time {
          font-size: 12px;
          color: #9fa1ae;
          margin-left: 10px;
          display: inline-block;
        }

        .price {
          background-size: contain;
          background: url("https://onboarding.epam.com/static/media/leaf.e795c76e.svg") no-repeat left;
          font-size: 14px;
          color: #525462;
          display: inline-block;
          padding-left: 28px;
          float: right;
          margin-top: 10px;
          margin-right: 7px;
        }
      }
    }

    .chat {
      padding: 10px;
      max-height: 300px;
      overflow: auto;

      .message {
        width: 100%;
        position: relative;
        display: block;

        .inner {
          box-shadow: 0 1px 2px 0 , 0 3px 10px 0 #2c2f3c0f;
          margin: 10px;
          font-size: 13px;
          padding: 20px 15px 5px;
          width: fit-content;
          position: relative;
          display: block;

          .date {
            color: #9fa1ae;
            position: absolute;
            right: 5px;
            top: 3px;
            font-size: 9px;
            opacity: .8;
          }
        }

        &.not-mine {
          overflow: auto;

          .inner {
            float: right;
          }
        }
      }

      .applied-alert {
        margin: 30px 10px 10px;
      }
    }

    .actions-area {
      padding: 20px;
      background: linear-gradient(242.61deg, #869feb33 0%, #436ad633 100%);

      .send-message-form {
        display: block;
        position: relative;

        .alert-danger {
          position: absolute;
          color: #ff0000;
          top: -16px;
          left: 0;
          font-size: 10px;
        }

        .message {
          border: 1px solid #ced0db;
          box-shadow: 0 1px 2px 0 #2c2f3c0f, 0 3px 10px 0 #2c2f3c17;
          color: #474a59;
          width: 100%;
          padding: 8px 10px;
          border-radius: 3px;
        }

        .btn-send {
          position: absolute;
          right: 0;
          display: inline-flex;
          align-items: center;
          top: 0;
          border: 0;
          background: none;
          height: 100%;
          padding: 0 10px;
          color: #869febcc;
          cursor: pointer;
        }
      }
    }
  }
}
